/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useContext, useEffect, useState } from 'react';
import {
    array, bool, object,
} from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import PriceRange from '../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/PriceRange';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import DynamicPrice from '../GraphqlProductPage/Partials/GraphqlPDPSkus/DynamicPrice';
import { getIsBot } from '../../../../state/ducks/App/App-Selectors';
import { getEnterpriseId } from '../../../../state/ducks/Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import { getUserSubmittedProductFilterZipcode, getPassportSubscriptionStatus } from '../../../../state/ducks/Common/Common-Selectors';
import { CrossMerch } from '../CrossMerchContainer/CrossMerchContainer';
import replaceSpecialChar from '../../../helpers/replaceSpecialChar';
import DynamicPriceSkeleton from '../GraphqlSkeletonComponents/DynamicPriceSkeleton';
import { dateStringReformat } from '../GraphqlProductPage/Partials/common/commonProductPageHelper';
import PriceBreakDownUi from '../GraphqlCategoryPage/Partials/GraphqlProductContainer/Partials/GraphqlSimpleProduct/DesktopSimpleProduct/PriceBreakDownUi';
import { getIsAuthenticatedStatus, getIsPassportUserLoggedIn } from '../../../../state/ducks/Member/ducks/Common/Common-Selectors';
import { getPassportData } from '../../../../state/ducks/Passport/Passport-Selectors';
import validatePassport from '../../../helpers/validatePassport';

const useStyles = makeStyles((theme) => ({
    cardWrapper: {
        display: 'grid',
        rowGap: '64px',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '12px',
    },
    card: {
        display: 'grid',
        position: 'relative',
        cursor: 'pointer',
        width: '100%',
        maxWidth: '418px',
        gridTemplateColumns: '1fr 1fr',
        '@media (max-width: 767px)': {
            flexDirection: 'column',
            display: 'flex',
            maxWidth: '210px',
        },
        '&:not(:last-child)': {
            marginRight: '12px',
        },
    },
    cardImage: {
        maxWidth: '210px', // as per mockups
        '& img': {
            display: 'grid',
            width: '100%',
            borderBottomLeftRadius: '8px',
            borderTopLeftRadius: '8px',
            height: '100%',
        },
        '@media (max-width: 767px)': {
            width: '100%',
            maxWidth: '100%',
            '& img': {
                borderBottomLeftRadius: '0px',
                borderTopRightRadius: '8px',
            },
        },
    },

    productName: {
        overflow: 'hidden',
        fontSize: '20px',
        fontStyle: 'normal',
        fontFamily: 'Lato',
        lineHeight: '30px',
        letterSpacing: '0.2px',
        color: theme.palette.upsellsTextColor || '#2F2F2F',
        textDecoration: 'none',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2,
        '@media (max-width: 767px)': {
            fontSize: '16px',
        },
    },
    productSku: {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '24px',
        letterSpacing: '0.16px',
        textTransform: 'capitalize',
        color: theme.palette.upsellsTextColor || '#2F2F2F',
        '@media (max-width: 767px)': {
            fontSize: '12px',
        },
    },
    productPrice: {
        fontSize: '20px',
        fontStyle: 'normal',
        fontFamily: 'Lato',
        lineHeight: '30px',
        letterSpacing: '0.2px',
        color: theme.palette.upsellsTextColor || '#2F2F2F',
        '@media (max-width: 767px)': {
            fontSize: '16px',
            lineHeight: '24px',
        },
    },
    relativeBox: {
        display: 'grid',
        alignContent: 'center',
        padding: '16px',
        width: '100%',
        maxWidth: '218px',
        backgroundColor: theme.palette.cms?.white || '#ffffff',
        '@media (max-width: 767px)': {
            alignContent: 'space-between',
            height: '100%',
            padding: '8px',
            maxWidth: '100%',
        },
    },
    availabilityMsg: {
        color: '#058942',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.14px',
        textWrap: 'balance',
        '@media (max-width: 767px)': {
            fontSize: '12px',
        },
    },
    button: {
        display: 'grid',
        width: '100%',
        padding: '7px 20px',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: '#C81842',
        fontSize: '14px',
        fontStyle: 'normal',
        fontFamily: 'Lato',
        lineHeight: '20px',
        letterSpacing: '0.56px',
        textTransform: 'capitalize',
        marginTop: '12px',
        borderRadius: '3px',
        border: '1px solid #C81842',
        backgroundColor: theme.palette.cms?.white || '#ffffff',
        cursor: 'pointer',
        '&:hover, &:focus, &:active': {
            outline: 'none',
        },
    },
    bottomSection: {
        width: '100%',
    },
    siteWideCollapseCard: {
        gridGap: '4%',
        gridTemplateColumns: '48% 48%',
        '& $card': {
            maxWidth: '220px',
            display: 'grid',
            overflow: 'hidden',
            gridTemplateColumns: '75px calc(100% - 75px)',
            '@media (max-width: 900px)': {
                gridTemplateColumns: '69px calc(100% - 69px)',
            },
            '@media (max-width: 767px)': {
                maxWidth: '100%',
            },
            '@media (max-width: 400px)': {
                gridTemplateColumns: '55px calc(100% - 55px)',
            },
        },
        '& $cardImage': {
            '& img': {
                borderTopRightRadius: '0px',
                borderBottomLeftRadius: '8px',
                width: '100%',
                aspectRatio: '25/27',
                '@media (max-width: 900px)': {
                    height: '100%',
                },
            },
        },
        '& $relativeBox': {
            alignContent: 'center',
            padding: '0px 8px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: '8px',
            maxWidth: '100%',
        },
        '& $productName': {
            fontSize: '14px',
            lineHeight: '20px',
            marginBottom: '4px',
        },
        '& $productSku': {
            fontSize: '14px',
            lineHeight: '20px',
        },
        '& $productPrice': {
            fontSize: '14px',
            lineHeight: '20px',
        },
    },
    siteWideExtendedCard: {
        gridGap: '4%',
        gridTemplateColumns: '48% 48%',
        '& $card': {
            grid: 'none',
            borderRadius: '8px',
            maxWidth: '220px',
            overflow: 'hidden',
            '@media (max-width: 1200px) and (min-width: 440px))': {
                minWidth: '190px',
            },
            '@media (max-width: 767px)': {
                maxWidth: '100%',
                '&:not(:last-child)': {
                    marginRight: '12px',
                },
            },
            '&:not(:last-child)': {
                marginRight: '16px',
            },
        },
        '& $cardImage': {
            maxWidth: '100%',
            '& img': {
                borderTopLeftRadius: '8px',
                borderTopRightRadius: '8px',
                borderBottomLeftRadius: '0px',
                width: '100%',
                aspectRatio: '97/106',
            },
        },
        '& $productName': {
            lineHeight: '24px',
            marginBottom: '4px',
        },
        '& $productPrice': {
            lineHeight: '24px',
            marginBottom: '4px',
        },
        '& $productSku': {
            marginBottom: '4px',
        },
        '& $relativeBox': {
            padding: '8px 12px 16px 12px',
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
            '&>div': {
                minHeight: '80px',
            },
        },

    },
}));

const GiftFinderProductUI = ({
    productList, availabilityData, collapseSiteWide, extandedcard, giftFinder,
}) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [productSkuPrices, setProductSkuPrices] = useState([]);
    const isDynamicPriceEnabled = useSelector((state) => getFeatureFlag('is-dynamic-pricing-enabled')(state));
    const isZipDynamicPriceEnabled = useSelector(getFeatureFlag('is-zip-dynamic-pricing-enabled'));
    const userSubmittedProductFilterZipcode = useSelector(getUserSubmittedProductFilterZipcode);
    const isBot = useSelector((state) => getIsBot(state));
    const enterpriseId = useSelector((state) => getEnterpriseId(state));
    const [dynamicPriceIsLoading, setDynamicPriceLoading] = useState(true);
    const isPassportUser = useSelector(getIsPassportUserLoggedIn);
    const isAuthenticated = useSelector(getIsAuthenticatedStatus);
    const passportData = useSelector(getPassportData);
    const isPassportMember = validatePassport('isActivePassportUser', passportData);
    const whichCollectionPageRestructure = useSelector(getFeatureFlag('which-collection-page-restructure'));
    const collectionRestructureVariantB = whichCollectionPageRestructure?.toLowerCase() === 'variantb';

    const isPassportAdded = useSelector(getPassportSubscriptionStatus);
    let variantList = [];
    if ((isPassportMember && isPassportUser) || isPassportAdded) {
        variantList = [{
            name: 'CUSTOMER_TYPE',
            value: 'Passport',
        }];
    } else if (isAuthenticated) {
        variantList = [{
            name: 'CUSTOMER_TYPE',
            value: 'Registered',
        }];
    }

    if (!whichCollectionPageRestructure) {
        return <></>;
    }
    // declaring Dynamic price call instance
    const dynamicPriceInstance = new DynamicPrice(productList.map((item) => ({ id: item.partNumber })), enterpriseId, false || '', null, true, variantList);

    const {
        getRetailPrice, getSalePrice,
    } = dynamicPriceInstance;

    useEffect(() => {
        if (isBot || !isDynamicPriceEnabled) {
            setProductSkuPrices([]);
            setDynamicPriceLoading(false);
            return;
        }

        if (!isZipDynamicPriceEnabled && !isDynamicPriceEnabled) {
            setProductSkuPrices([]);
            setDynamicPriceLoading(false);
            return;
        }

        const useZipcode = isZipDynamicPriceEnabled;

        setDynamicPriceLoading(true);
        dynamicPriceInstance.call(useZipcode, userSubmittedProductFilterZipcode).then((productPrices) => {
            setProductSkuPrices(productPrices || []);
            setDynamicPriceLoading(false);
        }).catch(() => {
            setDynamicPriceLoading(false);
        });
    }, [
        isDynamicPriceEnabled,
        userSubmittedProductFilterZipcode,
    ]);
    const classes = useStyles();
    const { presentation_family } = useContext(CrossMerch);
    const availabilityMessage = (availability) => {
        const { earliestShipDate, deliveryMessage } = availability || {};
        const dateObject = new Date(dateStringReformat(earliestShipDate)).getTime();
        const earliestCmsDate = new Date(dateStringReformat(availabilityData?.date)).getTime();
        if (dateObject <= earliestCmsDate) {
            return <div className={classes.availabilityMsg} data-testid="earliestDefaultShipDate">{availabilityData?.availability_copy || deliveryMessage}</div>;
        }
        return <></>;
    };
    let siteWideClass;
    if (collapseSiteWide) {
        siteWideClass = `${classes.siteWideCollapseCard} ${classes.cardWrapper}`;
    } else if (extandedcard) {
        siteWideClass = `${classes.siteWideExtendedCard} ${classes.cardWrapper}`;
    } else {
        siteWideClass = classes.cardWrapper;
    }
    const generateCard = (product, index) => {
        let updatedPrices = {
            retail: (product?.prices || []).filter((item) => item?.type === 'retail'),
            sale: (product?.prices || []).filter((item) => item?.type === 'sale'),
        };
        if (productSkuPrices?.length > 0) {
            const dynamicPriceList = productSkuPrices?.find?.((item) => item?.id === product.id)?.prices || [];
            if (dynamicPriceList.length > 0) {
                updatedPrices = {
                    retail: [{ value: getRetailPrice(dynamicPriceList) }],
                    sale: [{ value: getSalePrice(dynamicPriceList) }],
                };
            }
        }
        const trackingEventCategory = giftFinder ? 'Gift Finder FLR' : 'Fast Lane Recommender';

        const handleOnClick = () => {
            if (!collapseSiteWide) {
                dispatch(trackEvent({
                    eventCategory: trackingEventCategory,
                    eventAction: 'Click',
                    eventLabel: product?.id || `Sku_${index}`,
                }));
                history.push(product?.parentProduct?.seo?.url, { selectedId: product?.id });
            }
        };
        return (
            <div className={classes.card}>
                <div
                    role="button"
                    onKeyDown={() => {
                    }}
                    tabIndex={0}
                    className={`${classes.cardImage}`}
                    onClick={() => handleOnClick()}
                >
                    <img src={`${product.image.path}${product.image.name}c.jpg?auto=webp`} alt={ReactHtmlParser(product.name)} />
                </div>
                <div className={classes.relativeBox}>
                    <div
                        role="button"
                        onKeyDown={() => {
                        }}
                        tabIndex={0}
                        className={classes.sectionWrapper}
                        onClick={() => handleOnClick()}
                    >
                        <strong className={classes.productName}>
                            {ReactHtmlParser(replaceSpecialChar(product.parentProduct.name, false))}
                        </strong>
                        {!collapseSiteWide && <div className={classes.productSku}>{product?.name}</div>}
                    </div>
                    <div className={classes.bottomSection}>
                        <div
                            role="button"
                            onKeyDown={() => {
                            }}
                            tabIndex={0}
                            onClick={() => handleOnClick()}
                            className={`${classes.productPrice}`}
                        >
                            {dynamicPriceIsLoading
                                ? <DynamicPriceSkeleton priceLineOnly /> : (
                                    <PriceRange
                                        presentationFamily={presentation_family}
                                        skuPriceRange={updatedPrices}
                                        isMobile={false}
                                        sisterBrandProduct={false}
                                        collectionRestructureEnabled={collectionRestructureVariantB}
                                    />
                                )}
                        </div>
                        <PriceBreakDownUi dynamicPriceData={(productSkuPrices || [])?.find((item) => item?.partNumber === product.partNumber)} />
                        {!collapseSiteWide && (
                            <div
                                role="button"
                                onKeyDown={() => {
                                }}
                                tabIndex={0}
                                onClick={() => handleOnClick()}
                            >
                                {availabilityMessage(product.availability)}
                                <strong
                                    className={classes.button}
                                >See Details
                                </strong>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className={siteWideClass}>
            {productList?.map?.((product, index) => generateCard(product, index))}
        </div>
    );
};

GiftFinderProductUI.propTypes = {
    productList: array.isRequired,
    availabilityData: object,
    collapseSiteWide: bool,
    extandedcard: bool,
    giftFinder: bool,
};

GiftFinderProductUI.defaultProps = {
    collapseSiteWide: false,
    availabilityData: {},
    extandedcard: false,
    giftFinder: false,
};
export default GiftFinderProductUI;
