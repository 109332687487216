/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SkeletonStyles from './SkeletonStyles';

const useStyles = makeStyles({
    // Import Skeleton layout
    ...SkeletonStyles,

    // Page Elements
    root: {
        width: '100%',
    },
    pageWrapper: {
        display: 'flex',
    },
    blockBody: {
        width: '100%',
        height: '202px',
        margin: '5px',
    },
});

const UpsellsSkeleton = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <div className={classes.pageWrapper}>
                <div className={`${classes.blockBody} ${classes.block}`} />
                <div className={`${classes.blockBody} ${classes.block}`} />
            </div>
        </div>
    );
};

export default UpsellsSkeleton;
