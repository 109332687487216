/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import mbpLogger from 'mbp-logger';
import {
    bool,
    object,
    string,
} from 'prop-types';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import findSkuByPartNumbers from '../../../gql/queries/findSkuByPartNumbers';
import { GRAPHQL_ENV } from '../../../gql';
import { getBrand } from '../../../pages/Account/state/ducks/Common/Common-Selectors';
import UpsellsSkeleton from '../GraphqlSkeletonComponents/UpsellsSkeleton';
import GiftFinderProductUI from './GiftFinderProductUI';

const GiftFinderProductQuery    = ({
    partNumbers, availabilityData, extandedcard, collapseSiteWide, giftFinder,
}) => {
    const brand = useSelector(getBrand);

    const RECOMMENDATION_QUERY = findSkuByPartNumbers(
        brand.domain,
        partNumbers,
        `brand
        brandId
        id
        partNumber
        name
        prices {
            currency
            type
            value
        }
        image {
            name
            path
        }
        availability {
            availabilityIndicator
            published
            earliestShipDate
            deliveryMessage
        }
        parentProduct {
            name
            seo {
              url
            }
        }`,
    );
    const { loading, error, data } = useQuery(RECOMMENDATION_QUERY);
    if (loading) {
        return <UpsellsSkeleton height="200px" width="100%" />;
    }
    if (error) {
        mbpLogger.logError({
            appName: process.env.npm_package_name,
            query: RECOMMENDATION_QUERY,
            jsError: error,
            component: 'GiftFinderProductQuery.js',
            message: `Failed to fetch content data for: content type on brand ${brand}`,
            env: GRAPHQL_ENV,
        });
    }
    if (!data) {
        mbpLogger.logWarning({
            appName: process.env.npm_package_name,
            query: RECOMMENDATION_QUERY,
            component: 'GiftFinderProductQuery.js',
            message: 'No data returned for query',
            env: GRAPHQL_ENV,
        });
        return null;
    }
    const productList = data?.findSkuByPartNumbers || [];

    return (
        <GiftFinderProductUI productList={productList} availabilityData={availabilityData} extandedcard={extandedcard} collapseSiteWide={collapseSiteWide} giftFinder={giftFinder} />);
};

GiftFinderProductQuery.propTypes = {
    partNumbers: string.isRequired,
    availabilityData: object,
    extandedcard: bool,
    collapseSiteWide: bool,
    giftFinder: bool,
};

GiftFinderProductQuery.defaultProps = {
    availabilityData: {},
    extandedcard: false,
    collapseSiteWide: false,
    giftFinder: false,
};

export default GiftFinderProductQuery;
