/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, bool } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SkeletonStyles from './SkeletonStyles';

const styles = () => ({
    // Import Skeleton layout
    ...SkeletonStyles,

    // Page Elements
    root: {},
    pageWrapper: {
        display: 'flex',
    },
    blockBody: {
        width: '100%',
        height: '202px',
        margin: '5px',
    },
});

const DynamicPriceSkeleton = ({ classes, priceLineOnly }) => {
    if (priceLineOnly) {
        return (
            <div className={classes.root}>
                <div className={`${classes.textMD}`} />
            </div>
        );
    }
    return (
        <div className={classes.root}>
            <div className={`${classes.textMW}`} />
            <div className={`${classes.textLG}`} />
            <div className={classes.pageWrapper}>
                <div className={`${classes.blockBody} ${classes.block}`} />
                <div className={`${classes.blockBody} ${classes.block}`} />
            </div>
        </div>
    );
};
DynamicPriceSkeleton.propTypes = {
    classes: object.isRequired,
    priceLineOnly: bool,
};

DynamicPriceSkeleton.defaultProps = {
    priceLineOnly: false,
};
export default withStyles(styles)(DynamicPriceSkeleton);
